export function ready(callback){
    if (document.readyState!='loading') {
        // in case the document is already rendered
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}

export function q(sel) { return document.querySelector(sel) }
export function qAll(sel) { return document.querySelectorAll(sel) }
export function qAllDo(sel, fn) { qAll(sel).forEach((el) => { fn(el) }) }
export function disable(sel, val=true) { qAllDo(sel, (el) => { el.disabled = val }) }
export function hide(sel) { qAllDo(sel, (el) => { el.classList.add('hidden') }) }
export function show(sel) { qAllDo(sel, (el) => { el.classList.remove('hidden') }) }
export function showHide(sel, p) { if (p) show(sel); else hide(sel) }
export function text(sel, text) { qAllDo(sel, (el) => { el.innerText = text }) }
export function removeClass(c) { qAllDo('.' + c, e => e.classList.remove(c)) }
