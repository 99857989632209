import * as doc from './doc'

var cart_updates = {}
var update_cart_server_timeout = null

const cur_fmt = new Intl.NumberFormat('en-NZ', {style: 'currency', currency: 'NZD'})

function update_cart_server(delivery_location) {
    clearTimeout(update_cart_server_timeout)  // delivery_location changes call update_cart_server directly
    fetch('/cart/update', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cart_updates: cart_updates,
                               delivery_location: delivery_location })
    })
    .then(response => response.json())
    .then(data => {
        if (!data.error) {
            doc.text('.insufficient', '')
            doc.text('.cart-subtotal', data.subtotal)
            doc.text('.cart-GST', data.GST)
            doc.text('.cart-total', data.total)
            doc.text('.cart-total-delivery', data.total_delivery)
            doc.text('.delivery-desc', data.delivery_desc)
            doc.text('.delivery-message', data.delivery_message)
            doc.text('.delivery-total', data.delivery_total)

            for (const sku in data.messages) {
                doc.text('#message-' + sku, data.messages[sku])
            }

            if (data.can_checkout) {
                doc.show('.can-checkout')
                doc.hide('.cannot-checkout')
            } else {
                doc.show('.cannot-checkout')
                doc.hide('.can-checkout')
            }
        }
    })

    cart_updates = {}
}

function update_cart(sku, qty) {
    if (window.fbq) {
        window.fbq('track', 'AddToCart');
    }
    doc.text('.cart-total-delivery', '???')
    cart_updates[sku] = qty
    clearTimeout(update_cart_server_timeout)
    update_cart_server_timeout = setTimeout(update_cart_server, 500)
}

//function each_el(sku) { return document.getElementById('each-'+sku) }
export function total_el(sku) { return document.getElementById('total-'+sku) }

export function total_line(cart_info, sku, qty) {
    const variant = cart_info[sku]
    var each
    console.log(variant)
    if (variant.is_on_special) {
        each = variant['on_special']
    }
    else if (variant.is_edge_tape) {
        if (qty < 100) {
            each = variant['fifty_plus']
        } else {
            each = variant['hundred_plus']
        }
    } else {
        if (qty < 9) {
            each = variant['each']
        } else if (qty < variant['pack_size']) {
            each = variant['nine_plus']
        } else {
            each = variant['pack']
        }
    }
    
    return [cur_fmt.format(each), cur_fmt.format(qty * each)]
}

export function updateQty(cart_info, sku, qty) {
    var each, total
    [each, total] = total_line(cart_info, sku, qty)
    doc.text('#each-'+sku, each)
    total_el(sku).innerText = total
    update_cart(sku, qty)
}

export function parseQty(n) {
    // anything invalid is treated as 0
    if (n.indexOf('.') != -1) {
        return 0 // reject decimals
    } else {
        n = +n // this coerces any plain numberic string to number
        if (isNaN(n) || n < 0) { n=0 }
        return n
    }
}

export function qtyChanged(cart_info, sku, e) {
    const qty = parseQty(e.target.value)
    updateQty(cart_info, sku, qty)
}

export function setup_cart_page(cart_info) {
    doc.ready(() => {
        doc.qAllDo('.select-location', (el) => {
            el.addEventListener('input', (e) => {
                update_cart_server(e.target.value)
            })
        })

        doc.qAllDo('input[name=pickup_delivery]', (el) => {
            el.addEventListener('click', (e) => {
                const pickup = e.target.value == 'pickup'
                const region_select = doc.q('#region-' + doc.q('#regions').value)
                const selected_region = region_select ? region_select.value : ''
                doc.disable('.delivery-region-box select', pickup)

                update_cart_server(pickup ? 'pickup' : selected_region)
            })
        })

        doc.q('#regions').addEventListener('input', (e) => {
            doc.hide('.select-location')
            const sel = '#region-' + e.target.value
            const qsel = doc.q(sel)
            doc.show(sel)
            if (qsel) qsel.value = ''
            update_cart_server('')
        })

        doc.qAll('.qty').forEach((el) => {
            el.addEventListener('input', cartQtyChange)
        })

        doc.q('#clear-cart').addEventListener('submit', (e) => {
            e.preventDefault()
            if (confirm("Empty the cart?")) {
                doc.q('#clear-cart').submit()
            }
        })

        function cartQtyChange(e) {
            qtyChanged(cart_info, e.target.attributes['data-sku'].value, e)
        }
    })
}

