import * as doc from "./doc"

var slideIndex = [1, 1, 1];
var slideId = ["mySlides", "mySlides2", "mySlides3"]

function plusSlides(n, no) {
    showSlides(slideIndex[no] += n, no);
}
function currentSlide(n, no) {
    showSlides(slideIndex[no] = n, no);
}
function showSlides(n, no) {
    var i;
    var x = document.getElementsByClassName(slideId[no]);
    if (n > x.length) { slideIndex[no] = 1 }
    if (n < 1) { slideIndex[no] = x.length }
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    x[slideIndex[no] - 1].style.display = "block";
}

// var hSlideIndex = 0;
// carousel()

// function carousel() {
//   var i;
//   var x = document.getElementsByClassName("mySlides");
//   for (i = 0; i < x.length; i++) {
//     x[i].style.display = "none";  
//   }
//   hSlideIndex++;
//   if (hSlideIndex > x.length) {hSlideIndex = 1}    
//   x[hSlideIndex-1].style.display = "block";  
//   setTimeout(carousel, 3000); // Change image every 2 seconds
// }


export function setup() {
    doc.ready(() => {
        window.plusSlides = plusSlides
        window.currentSlide = currentSlide
    })
}
// export function homeS() {
//     doc.ready(() => {
//         carousel();
//     })
// }