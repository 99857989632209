import * as doc from './doc'
import * as cart from './cart-page'

function setup(cart_info) {
    function qty_el(sku) { return document.getElementById('qty-'+sku) }
    function add_button(sku) { return document.getElementById('add-'+sku) }

    function add(sku) {
        const el = qty_el(sku)
        const qty = cart.parseQty(el.value) + (cart_info[sku].is_edge_tape ? 50 : 1)
        el.value = qty
        cart.updateQty(cart_info, sku, qty)
    }

    // Attach events for variants table
    for (var sku in cart_info) {
        const variant = cart_info[sku]
        qty_el(sku).value = variant['qty']; // don't delete this semicolon!!!
        var [_, total] = cart.total_line(cart_info, sku, variant['qty'])     // eslint-disable-line no-unused-vars
        cart.total_el(sku).innerText = total

        qty_el(sku).addEventListener('input', cart.qtyChanged.bind(null, cart_info, sku))
        add_button(sku).addEventListener('click', add.bind(null, sku))
    }

    // Attach events to thumbnails
    const productImg = doc.q('.product-img')
    doc.qAll('.thumbnails img').forEach((el) => {
        el.addEventListener('click', (e) => {
            productImg.src = e.target.attributes['data-src'].value
        })
    })
}

export function setup_product_page(cart_info) {
    doc.ready(() => { setup(cart_info) })
}
