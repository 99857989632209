import * as doc from './doc'

function setup(contact_url) {
    doc.q("#enquiry_type").addEventListener('input', (e) => {
        if (e.target.value == "Sample Request") {
            doc.show('#address-wrapper')
            doc.q('#message').placeholder = "Please list the products you would like samples of here..."
        } else {
            doc.hide('#address-wrapper')
            doc.q('#message').placeholder = "Please write your questions here..."
        }
    })

    // *** Setup validation
    var form = doc.q("#contact-form")

    form.addEventListener('submit', (e) => {
        e.preventDefault()

        const fields = {
            'First name': doc.q("input[name='First name']").value,
            'Last name': doc.q("input[name='Last name']").value,
            'Email': doc.q("input[name='Email']").value,
            'Phone': doc.q("input[name='Phone']").value,
            'Company name': doc.q("input[name='Company name']").value,
            'Inquiry type': doc.q("select[name='Enquiry type']").value,
            'Address': doc.q("textarea[name='Address']").value,
            'Message': doc.q("textarea[name='Message']").value,
        }

        doc.show('#spinner')

        fetch(contact_url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(fields)
        })
        .then(response => response.json() )
        .then(data => {
            if (data.error) throw data.error
            doc.hide("#spinner")
            doc.hide("#form-fields")
            doc.hide(".contact-error")
            doc.show(".contact-ok")
        })
        .catch(() => {
            doc.hide("#spinner")
            doc.show(".contact-error")
        })
    })
}


export function setup_about_page(contact_url) {
    doc.ready(() => setup(contact_url))
}
