import * as doc from './doc'
import {loadStripe} from '@stripe/stripe-js';

async function setup(stripe_key, stripe_client_secret, validate_url, buy_url) {
    // *** Setup validation
    var form = doc.q("#payment-form")
    var buy_now_el = doc.q('.buy-now')
    var bill_to_delivery_el = doc.q('#bill_to_delivery')
    var fields = new Map()
    var validate_timeout = null
    var form_valid = true
    var card_valid = false

    if (bill_to_delivery_el) {
        bill_to_delivery_el.addEventListener('click', () => {
            doc.q('.billing_address').classList.toggle('hidden')
        })
    }

    doc.qAll('#payment-form input').forEach((el) => {
        el.addEventListener('input', input.bind(null, el))
        fields.set(el.id, { el: el,
                            error_el: doc.q('#error-' + el.id),
                            pristine: el.value.trim() == '' })
    })

    doc.q('#email').focus()

    function input(el) {
        if (el.value.trim() != '') {
            fields.get(el.id).pristine = false
        }
        clearTimeout(validate_timeout)
        validate_timeout = setTimeout(validate, 500)
    }

    function validate() {
        fetch(validate_url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(gatherForm())
        })
        .then(response => response.json(), () => { formError("There was a server error. Please try again.") } )
        .then(scatterErrors)
    }

    function gatherForm() {
        var data = {}

        for (var [key, field] of fields) {
            if (!field.pristine) {
                data[key] = field.el.type == 'checkbox' ? field.el.checked : field.el.value
            }
        }

        return data
    }

    function scatterErrors(errors) {
        form_valid = true

        formError(errors.error)

        for (var [key, field] of fields) {
            var error = errors.field_errors[key]
            field.error_el.innerText = error || ''
            if (error) {
                field.pristine = false
                form_valid = false
            }
        }
        update_buy_button()
        return form_valid && !errors.error
    }

    function update_buy_button() {
        buy_now_el.disabled = !(form_valid && card_valid)
    }

    // *** Setup Stripe

    var style = {
        base: {
            backgroundColor: 'white',
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
            color: "#32325d"
            }
        },
        invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }

    var stripe = await loadStripe(stripe_key)
    doc.hide('#card-spinner')
    var elements = stripe.elements()
    var card = elements.create("card", { style: style, hidePostalCode: true })

    // Stripe injects an iframe into the DOM
    doc.show('#card-element')
    card.mount("#card-element")

    card.on("change", function (event) {
        // Disable the Pay button until a valid card has been entered
        card_valid = event.complete
        document.querySelector("#card-error").textContent = event.error ? event.error.message : ""
        update_buy_button()
    })


    form.addEventListener("submit", function(event) {
        event.preventDefault()
        payWithCard(stripe, card, stripe_client_secret)
    })

    function payWithCard(stripe, card) {
        loading(true)
        // Start the payment on our server
        fetch(buy_url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(gatherForm())
        })
        .then(response => response.json(), () => { formError("There was a server error. Please try again.") } )
        .then(data => {

                if (scatterErrors(data)) {
                    // Continue payment with Stripe
                    loading(true)
                    stripe
                    .confirmCardPayment(data.clientSecret, {
                        payment_method: {
                            card: card
                        },
                        return_url: data.successCallback // URL for Stripe to notify our server of result
                    })
                    .then(function(result) {
                        if (result.error) {
                            // Error from Stripe
                            formError(result.error.message)
                        } else {
                            // Payment succeeded
                            window.location.replace(data.successRedirect)

                        }
                    });
                }
            },
            () => { formError("I could not understand the server. Please try again.") }
        )
    }

    function loading(p) {
        document.querySelector("#buy-now").disabled = p
        document.querySelector("#spinner").style.display = p ? 'inline' : 'none'
    }

    function formError(err) {
        loading(false)
        doc.q("#form-error").textContent = err
    }
}

export function setup_checkout_page(stripe_key, stripe_client_secret, validate_url, buy_url) {
    doc.ready(() => { setup(stripe_key, stripe_client_secret, validate_url, buy_url) })
}
