import * as doc from "./doc"

// Hamburger menu
doc.ready(() => {
    const mainNavEl = doc.q("#main-nav")
    const burgerEl = doc.q("#main-nav #burger")
    const closerEl = doc.q("#main-nav #closer")

    mainNavEl.addEventListener('click', (e) => { e.stopPropagation() })
    burgerEl.addEventListener('click', () => { mainNavEl.classList.add('open') })
    closerEl.addEventListener('click', () => { mainNavEl.classList.remove('open') })
    document.addEventListener('click', () => { mainNavEl.classList.remove('open') })
})


// HIDE/SHOW HEADER ON SCROLL EVENT
doc.ready((function () {
    var docEl = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || docEl.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;

    var header = doc.q("#header-wrap");

    var checkScroll = function () {

        /*
          ** Find the direction of scroll
          ** 0 - initial, 1 - up, 2 - down
          */

        curScroll = w.scrollY || docEl.scrollTop;
        if (curScroll > prevScroll) {
            //scrolled up
            direction = 2;
        }
        else if (curScroll < prevScroll) {
            //scrolled dowmn
            direction = 1;
        }

        if (direction !== prevDirection) {
            toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;
    };
    var toggleHeader = function (direction, curScroll) {
        if (direction === 2 && curScroll > 107) {

            //107 - the height of header in px

            header.classList.add('hider');
            prevDirection = direction;
        }
        else if (direction === 1) {
            header.classList.remove('hider');
            prevDirection = direction;
        }
    }

    window.addEventListener('scroll', checkScroll);
}))


