import * as doc from './doc'

function showAllCategoryProducts() {
    doc.removeClass('selected')
    doc.removeClass('open')
    doc.q('.show-all').classList.add('selected')
    doc.qAllDo('.product-block', e => e.style.display = 'block' )
}

function showCategoryProducts(category) {
    const el = doc.q('[data-filter-category=' + category + ']')
    doc.removeClass('selected')
    doc.removeClass('open')
    if (el.classList.contains('sub-cat')) {
        el.parentNode.classList.add('selected')
        el.parentNode.parentNode.parentNode.classList.add('open')
    } else {
        el.parentNode.classList.add('selected')
        el.parentNode.classList.add('open')
    }
    doc.qAll('.product-block').forEach((el) => { el.style.display = 'none' })
    doc.qAll('.cat-' + category).forEach((el) => { el.style.display = 'block' })
}

function setup() {
    doc.q('.show-all').addEventListener('click', showAllCategoryProducts)
    doc.qAll('[data-filter-category]').forEach((el) => {
        const category = el.attributes['data-filter-category'].value
        el.addEventListener('click', showCategoryProducts.bind(null, category))
    })

    const initial_category = location.hash
    if (!initial_category || initial_category == '#all') {
        showAllCategoryProducts()
    } else {
        showCategoryProducts(initial_category.substring(1))
    }
}

export function setup_products_page() {
    doc.ready(setup)
}
